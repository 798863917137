<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>费率</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="approval-detail-info">
            <el-button v-if="detail.sign" class="printing" type="primary" @click="printDialogVisible = true">
                打印
            </el-button>
            <div class="approval-detail-container">
                <div class="name-container">
                    <span>{{ detail.true_name | sub_name }}</span>
                    <div class="state-container">
                        <div class="content">{{ detail.true_name }}</div>
                        <div class="state">{{ detail.state | state }}</div>
                    </div>
                </div>
                <!--        <div class="approval-detail-title">-->
                <!--          <span class="approval-detail-tip">审批编号：</span-->
                <!--          ><span class="approval-detail-content">{{ detail.shenpi_no }}</span>-->
                <!--        </div>-->
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content">{{ detail.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project_name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.chairman }}</span>
                </div>
                <project-info :cid="detail.cid" :info="detail.project_attach"></project-info>
                <!--<div class="approval-detail-title">-->
                <!--<span class="approval-detail-tip">费率类型：</span-->
                <!--&gt;<span class="approval-detail-content">{{ detail.type === 1 ? '项目' : detail.type === 2 ? '会议' : '专项'}}</span>-->
                <!--</div>-->

                <div class="approval-detail-title">
                    <span class="approval-detail-tip">二维码：</span>
                    <div class="approval-detail-content">
                        <el-image :src="detail.qrcode" style="width: 150px; height: 150px"></el-image>
                    </div>
                </div>
            </div>
            <div class="approval-detail-container">
                <div class="approval-list-tip">签字信息</div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">支持类型：</span>
                    <span class="approval-detail-content">{{ detail.support_type_new && detail.support_type_new.length ?   detail.support_type_new.map(item=>{ return item == 1 ? '赞助' : '捐赠'}).join(',') : '--' }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ detail.support_type === 1 ? '申请服务费率（含税）为' : '申请服务费率为'}}：</span>
                    <span class="approval-detail-content">{{ parseFloat((detail.rate * 100).toFixed(2)) }}%</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">申请单位：</span>
                    <span class="approval-detail-content">{{ detail.company }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">申请理由：</span>
                    <span class="approval-detail-content">{{ detail.reason }}</span>
                </div>
                <div class="approval-detail-title"  v-if="detail.sign" >
                    <span class="approval-detail-tip">签名：</span>
                    <div class="approval-detail-content">
                        <el-image :src="detail.sign" style="width: 550px; height: auto"></el-image>
                    </div>
                </div>
            </div>
            <div class="approval-list-container">
                <div class="approval-list-tip">审批流程</div>

                <div class="approval-list">
                    <div class="name-container active">
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i class="el-icon-success"></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div class="info-time">{{ detail.create_time }}</div>
                            </div>
                            <div class="info-desc">发起申请</div>
                        </div>
                    </div>
                    <div :class="{ active: detail.state != 2 }" class="name-container">
            <span>
              签字
              <i
                  :class="detail.sign ? 'el-icon-success' : 'el-icon-remove'"
              ></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">
                                    确认人签字
                                </div>
                                <div v-if="detail.allSp[0].sp_time " class="info-time">
                                    {{ detail.allSp[0].sp_time | formatTime }}
                                </div>
                            </div>
                            <div class="info-desc">
                                {{ detail.sign ? "已签字" : "签字中" }}
                            </div>
                        </div>
                    </div>
                    <div v-if="detail.state != 2">
                        <div v-for="(item,index) in detail.shenpi"
                             :key="item.id">
                            <div
                                v-if="index === 0 ? true : detail.shenpi[index-1].state === 1"
                                :class="{ active:  item.state === 1 }"
                                class="name-container"
                            >
              <span
              >{{
                      item.true_name | sub_name
                  }}
                <i
                    :class="
                    item.state === 0
                      ? 'el-icon-remove'
                      : item.state === 1
                      ? 'el-icon-success'
                      : 'el-icon-error'
                  "
                ></i>
              </span>
                                <div class="info-container">
                                    <div class="info-top">
                                        <div class="info-content">
                                            {{ item.true_name
                                            }}<i v-if="item.state != 0"
                                        >({{ item.state | sp_state }})</i
                                        >
                                        </div>
                                        <div v-if="item.sp_time" class="info-time">
                                            {{ item.sp_time | formatTime }}
                                        </div>
                                    </div>
                                    <div v-if="item.state === 0" class="info-desc">
                                        {{ iself(item) ? "我（" : "" }}{{ item.state | sp_state
                                        }}{{ iself(item) ? "）" : "" }}
                                    </div>
                                    <div v-if="item.state !== 0" class="info-desc">
                                        {{ item.sp_remark }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="
              detail.shenpi[ detail.shenpi.length -1 ].state === 1
            "
                        class="name-container"
                    >
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i
                  :class="
                  detail.state === 5 ? 'el-icon-success' : 'el-icon-remove'
                "
              ></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">
                                    {{ detail.true_name }}
                                </div>
                                <div v-if="detail.allSp[detail.allSp.length - 1].sp_time " class="info-time">
                                    {{ detail.allSp[detail.allSp.length - 1].sp_time | formatTime }}
                                </div>
                            </div>
                            <div class="info-desc">
                                {{ detail.state === 5 ? "已确认" : "确认中" }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="approval-btn">
                <el-button
                    v-if="member_type == 1 &&(detail.state == 2 )"
                    class="approval-btn-left"
                    @click="toRevoke()">
                    撤回
                </el-button>
                <el-button
                    v-if="(member_type == 1 && detail.state == 1) ||
                     (member_type == 0 && isApprovaler)" class="approval-btn-left"
                    @click="shenpi_rejectedVisible = true">驳回
                </el-button>
                <el-button v-if="member_type == 1 && detail.state == 1"
                    :disabled="requestState"
                    :loading="requestState" class="approval-btn-right"
                    @click="confirmRate()">确认
                </el-button>
                <el-button
                    v-if="member_type == 0 && isApprovaler"
                    class="approval-btn-left"
                    @click="showDialog(2)">拒绝
                </el-button>
                <el-button
                    v-if="member_type == 0 && isApprovaler"
                    class="approval-btn-right"
                    @click="showDialog(1)">通过
                </el-button>
            </div>
        </div>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="editVisible"
            center
            width="30%"
        >
      <span slot="title" class="dialog-title">{{
              state === 1 ? "审批同意确认" : "审批拒绝原因"
          }}</span>
            <div class="dialog-container flex-center">
                <el-input
                    v-model="remark"
                    :placeholder="
            state === 1 ? '请输入审批同意意见' : '请输入审批拒绝原因'
          "
                    :rows="5"
                    resize="none"
                    type="textarea"
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button :disabled="requestState" :loading="requestState" type="primary" @click="toApproval()">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog :before-close="closeDialog" :visible.sync="shenpi_rejectedVisible"
                   center width="30%">
            <span slot="title" class="dialog-title">审批驳回</span>
            <div class="dialog-container flex-center">
                <el-input v-model="remark" placeholder="请输入审批驳回原因"
                          :rows="5" resize="none" type="textarea"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button :disabled="requestState" :loading="requestState" type="primary"
                           @click="shenpi_rejected()">确定</el-button>
              </span>
        </el-dialog>
        <el-tooltip placement="top" effect="light">
            <div slot="content">
                <div v-for="(item,index) in detail.shenpi_rejected" :key="index">
                    {{ item.true_name }}{{item.create_time | formatTime}}驳回了审批，驳回原因为：{{item.message}}<br/>
                </div>
            </div>
            <div class="record flex align-center justify-center" v-if="detail.shenpi_rejected && detail.shenpi_rejected.length">驳回<br>记录</div>
        </el-tooltip>
        <rate-print :detail="detail" :dialog-visible="printDialogVisible" @watchState="watchState"></rate-print>
    </div>
</template>

<script>
import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
import ElImage from "../../../node_modules/element-ui/packages/image/src/main.vue";
import config from "../../config/index";
import {getPathIndex, sub_name, ZHDate} from "../../tools";
import RatePrint from "./print/rate";
import ProjectInfo from "../components/ProjectInfo";

export default {
    components: {
        ProjectInfo,
        RatePrint,
        ElImage,
        ElButton,
    },
    name: "fprDetail",
    data() {
        return {
            pid: "",
            member_type: -1,
            jiesuan_jiaojie: [],
            isApprovaler: false,
            editVisible: false,
            shenpi_rejectedVisible: false,
            requestState: false,
            state: -1,
            detail: {},
            remark: "",
            printDialogVisible: false
        };
    },
    created() {
        this.$emit("setIndex", [0, getPathIndex(this.$route.query.type)]);
        this.init();
    },
    methods: {
        watchState() {
            this.printDialogVisible = false
        },
        showDialog(type) {
            this.editVisible = true;
            this.state = type;
            this.remark = this.state === 1 ? "同意" : "";
        },
        closeDialog() {
            this.remark = ''
            this.shenpi_rejectedVisible = false;
            this.editVisible = false;
            this.state = -1;
        },
        confirmRate() {
            this.requestState = true
            this.$api.apiContent
                .confirm({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: Number(this.$route.query.classify) - 9, //审批类型 1 项目 2会议  3自用
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: Number(this.$route.query.classify) - 9, //审批类型 10 项目 11会议  12自用
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then(() => {
                    this.$message.success("操作成功");
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        toApproval() {
            if (!this.remark) {
                this.$message.error(
                    this.state === 1 ? "请输入审批同意意见" : "请输入审批拒绝原因"
                );
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .projectShenpi({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: this.$route.query.classify, //审批类型 10 项目 11会议  12自用
                    id: this.pid,
                    state: this.state,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: this.$route.query.classify, //审批类型 10 项目 11会议  12自用
                            id: this.pid,
                            state: this.state,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        toRevoke() {
            this.$api.apiContent
                .revoke({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: this.$route.query.classify, //审批类型 10 项目 11会议  12自用
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: this.$route.query.classify, //审批类型 10 项目 11会议  12自用
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.backPage();
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        shenpi_rejected() {
            if (!this.remark) return  this.$message.error("请输入驳回原因")
            this.requestState = true
            this.$api.apiContent
                .shenpi_rejected({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: this.$route.query.classify, //审批类型 10 项目 11会议  12自用
                    state:2,
                    id: this.pid,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: this.$route.query.classify, //审批类型 10 项目 11会议  12自用
                            state:2,
                            id: this.pid,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        init() {
            this.detail = {}
            this.pid = this.$route.query.id;
            this.member_type = this.$route.query.type;
            this.$api.apiContent
                .infoProject({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: this.$route.query.cctype ? this.$route.query.cctype : "",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            type: this.$route.query.cctype ? this.$route.query.cctype : "",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    //                    res.data.history.map(item=>{
                    //                        item.desc = item.desc.replace(/<br>/g,' ')
                    //                        item.add_time = this.$tools.ZHDate(new Date(item.add_time*1000),1)
                    //                    })
                    res.data.allSp = res.data.shenpi
                    res.data.shenpi = res.data.shenpi.filter(item => {
                        return !item.is_user || item.is_user === 2
                    })
                    res.data.time =
                        this.$tools.ZHDate(new Date(res.data.start_time * 1000)) +
                        " 至 " +
                        this.$tools.ZHDate(new Date(res.data.end_time * 1000));
                    res.data.qrcode = config.urlImgStr + res.data.qrcode;
                    res.data.sign = res.data.sign ? config.urlImgStr + res.data.sign : "";
                    this.detail = res.data;
                    this.isApproval();
                })
                .catch(() => {
                    this.detail = {};
                });
        },
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        isApproval: function () {
            this.isApprovaler = false
            this.shenpi_rejectedVisible = false
            this.editVisible = false
            this.requestState = false
            this.detail.shenpi.map((item, index) => {
                if (item.user_id === this.$store.state.user.mid && item.state === 0) {
                    if (index === 0) {
                        this.isApprovaler = this.detail.state === 4;
                    } else {
                        if (this.detail.shenpi[index - 1].state === 1) {
                            this.isApprovaler = true;
                        }
                    }
                }
            });
        },
        backPage() {
            this.$tools.goBack();
        },
    },
    filters: {
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "已驳回";
                case 1:
                    return "等待发起人确认";
                case 2:
                    return "等待签字确认";
                case 3:
                    return "已撤销";
                case 4:
                    return "已签字,审核中";
                default:
                    return "已完成";
            }
        },
        sp_state(type) {
            switch (Number(type)) {
                case 0:
                    return "审批中";
                case 3:
                    return "未审批";
                case 1:
                    return "已通过";
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
    computed: {
        isSend: function () {
            return this.detail.user_id === this.$store.state.user.mid;
        },
    },
    watch: {
        $route: "init",
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .dialog-title {
        color: #000;
        font-size: 24px;
    }

    .dialog-footer {
        .el-button {
            width: 235px;
        }
    }

    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .approval-detail-info {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        min-height: 600px;
        padding-bottom: 100px;

        .printing {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .approval-btn {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 50%;
            position: absolute;
            bottom: 60px;
            padding-left: 120px;

            .approval-btn-left {
                width: 180px;
                background: transparent;
                border: 1px solid #3b77e7;
                color: #3b77e7;
            }

            .approval-btn-right {
                width: 180px;
                margin-left: 20px;
                background: #3b77e7;
                color: #ffffff;
            }
        }

        .approval-list-container {
            display: flex;
            flex-direction: row;
            padding: 40px 80px;

            .approval-list {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                .name-container {
                    position: relative;
                    flex-grow: 1;
                    display: flex;
                    padding-bottom: 50px;
                    flex-direction: row;

                    &.active {
                        &:before {
                            position: absolute;
                            background: #d3d4d6;
                            width: 1px;
                            top: 0px;
                            bottom: 0px;
                            left: 28px;
                            content: "";
                        }
                    }

                    .info-container {
                        flex-grow: 1;
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .info-top {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .info-content {
                                font-size: 20px;
                                color: #333333;

                                i {
                                    font-size: 14px;
                                }
                            }

                            .info-time {
                                font-size: 16px;
                                color: #999999;
                            }
                        }

                        .info-desc {
                            margin-top: 2px;
                            font-size: 16px;
                            color: #999999;
                        }

                        .copy-members {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 20px;

                            .copy-members-name {
                                margin-right: 10px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        border: 1px solid #3b77e7;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 16px;
                        color: #ffffff;
                        background-color: #3b77e7;
                        position: relative;

                        i {
                            position: absolute;
                            bottom: -5px;
                            right: -5px;
                            font-size: 18px;
                            width: 18px;
                            height: 18px;
                            overflow: hidden;

                            &.el-icon-remove {
                                color: #ee9900;
                            }

                            &.el-icon-success {
                                color: #13ce66;
                            }

                            &.el-icon-error {
                                color: #ff0000;
                            }
                        }
                    }
                }
            }

            .approval-list-tip {
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
                margin-top: 12px;
            }
        }

        .approval-detail-container {
            padding: 40px;
            border-bottom: 1px solid #f0f2f5;

            .approval-list-tip {
                margin-left: 40px;
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
            }

            .approval-detail-title {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                color: #999999;
                width: 100%;

                .approval-detail-tip {
                    flex: 1;
                    flex-shrink: 0;
                    text-align: right;

                    &.red {
                        color: red;
                    }
                }

                .approval-detail-content {
                    flex: 7;
                    flex-shrink: 1;
                    color: #333333;

                    &.red {
                        color: red;
                    }
                }
            }

            .name-container {
                display: flex;
                flex-direction: row;
                padding-left: 40px;

                .state-container {
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .content {
                        font-size: 22px;
                        color: #333333;
                    }

                    .state {
                        font-size: 16px;
                        margin-top: 6px;
                        color: #13ce66;
                    }
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 65px;
                    height: 65px;
                    border-radius: 65px;
                    text-align: center;
                    cursor: pointer;
                    /*line-height: 24px;*/
                    font-size: 20px;
                    color: #fff;
                    background-color: #3b77e7;
                }
            }
        }
    }
}
</style>
