<template>
    <el-dialog
        :before-close="closeDialog"
        :show-close="false"
        :visible.sync="dialogVisible"
        center
        width="7.9in">
        <span slot="title" class="title">打印预览</span>
        <div class="dialog-container">
            <div id="printTest">
                <div class="apply-title">费率申请</div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content">{{ detail.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{$getTitleByType('会议名称',detail.cid)}}：</span>
                    <span class="approval-detail-content">{{ detail.project_name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{$getTitleByType('会议日期',detail.cid)}}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                          <span class="approval-detail-tip">{{$getTitleByType('大会主席',detail.cid)}}：</span
                          ><span class="approval-detail-content">{{ detail.chairman }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">支持类型：</span>
                    <span class="approval-detail-content">{{ detail.support_type_new && detail.support_type_new.length ?   detail.support_type_new.map(item=>{ return item == 1 ? '赞助' : '捐赠'}).join(',') : '--' }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ detail.support_type === 1 ? '申请服务费率（含税）为' : '申请服务费率为'}}：</span>
                    <span class="approval-detail-content">{{ parseFloat((detail.rate * 100).toFixed(2)) }}%</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">申请单位：</span>
                    <span class="approval-detail-content">{{ detail.company }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">申请理由：</span>
                    <span class="approval-detail-content">{{ detail.reason }}</span>
                </div>
                <div class="approval-detail-container">
                    <div class="approval-detail-title">
                        <!--                        <span class="approval-detail-tip">签名：</span>-->
                        <div class="approval-detail-content">
                            <el-image :src="detail.sign" style="width: 100%; height: auto"></el-image>
                        </div>
                    </div>
                </div>

                <div class="approval-list-container">
                    <div class="approval-list-tip">审批流程</div>

                    <div class="approval-list">
                        <div class="name-container active">
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i class="el-icon-success"></i>
            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">{{ detail.true_name }}</div>
                                    <div class="info-time">{{ detail.create_time }}</div>
                                </div>
                                <div class="info-desc">发起申请</div>
                            </div>
                        </div>
                        <div :class="{ active: detail.state != 2 }" class="name-container">
            <span>
              签字
              <i
                  :class="detail.sign ? 'el-icon-success' : 'el-icon-remove'"
              ></i>
            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        确认人签字
                                    </div>
                                    <div v-if="detail.allSp[0].sp_time " class="info-time">
                                        {{ detail.allSp[0].sp_time | formatTime }}
                                    </div>
                                </div>
                                <div class="info-desc">
                                    {{ detail.sign ? "已签字" : "签字中" }}
                                </div>
                            </div>
                        </div>
                        <div v-if="detail.state != 2">
                            <div v-for="(item,index) in detail.shenpi"
                                 :key="item.id">
                                <div
                                    v-if="index === 0 ? true : detail.shenpi[index-1].state === 1"
                                    :class="{ active:  item.state === 1 }"
                                    class="name-container"
                                >
              <span
              >{{
                      item.true_name | sub_name
                  }}
                <i
                    :class="
                    item.state === 0
                      ? 'el-icon-remove'
                      : item.state === 1
                      ? 'el-icon-success'
                      : 'el-icon-error'
                  "
                ></i>
              </span>
                                    <div class="info-container">
                                        <div class="info-top">
                                            <div class="info-content">
                                                {{ item.true_name
                                                }}<i v-if="item.state != 0"
                                            >({{ item.state | sp_state }})</i
                                            >
                                            </div>
                                            <div v-if="item.sp_time" class="info-time">
                                                {{ item.sp_time | formatTime }}
                                            </div>
                                        </div>
                                        <div v-if="item.state === 0" class="info-desc">
                                            {{ iself(item) ? "我（" : "" }}{{ item.state | sp_state
                                            }}{{ iself(item) ? "）" : "" }}
                                        </div>
                                        <div v-if="item.state !== 0" class="info-desc">
                                            {{ item.sp_remark }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="
              detail.shenpi[ detail.shenpi.length -1 ].state === 1
            "
                            class="name-container"
                        >
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i
                  :class="
                  detail.state === 5 ? 'el-icon-success' : 'el-icon-remove'
                "
              ></i>
            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        {{ detail.true_name }}
                                    </div>
                                    <div v-if="detail.allSp[detail.allSp.length - 1].sp_time " class="info-time">
                                        {{ detail.allSp[detail.allSp.length - 1].sp_time | formatTime }}
                                    </div>
                                </div>
                                <div class="info-desc">
                                    {{ detail.state === 5 ? "已确认" : "确认中" }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取消</el-button>
            <el-button v-print="'#printTest'" class="printing" type="primary">打印</el-button>
      </span>
    </el-dialog>
</template>

<script>
import {sub_name, ZHDate} from "../../../tools";

export default {
    name: "rate-print",
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        detail: { //详情
            type: Object,
        },
    },
    methods: {
        closeDialog() {
            this.$emit('watchState', false)
        }
    },
    filters: {
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "已驳回";
                case 1:
                    return "等待发起人确认";
                case 2:
                    return "等待签字确认";
                case 3:
                    return "已撤销";
                case 4:
                    return "已签字,审核中";
                default:
                    return "已完成";
            }
        },
        sp_state(type) {
            switch (Number(type)) {
                case 0:
                    return "审批中";
                case 3:
                    return "未审批";
                case 1:
                    return "已通过";
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 16px;
    color: #333333;
    text-align: center;
    font-weight: bold;
}

.dialog-container {
    height: 600px;
    overflow-y: scroll;
    border: 1px solid #e1e1e1;

    &::-webkit-scrollbar {
        display: none;
    }
}

#printTest {
    background: #FFFFFF;
    padding: 20px;
    border: 1px solid #e1e1e1;
}

.apply-title {
    font-size: 22px;
    color: #333333;
    text-align: center;
    font-weight: bold;
    margin: 20px 0 50px;
}

.approval-detail-title {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    color: #999999;
    width: 100%;
    padding: 10px 0;

    .approval-detail-tip {
        flex: 1;
        flex-shrink: 0;

        &.red {
            color: red;
        }
    }

    .approval-detail-tip-length {
        flex-shrink: 0;

        &.red {
            color: red;
        }
    }

    .approval-detail-content {
        flex: 4;
        flex-shrink: 1;
        color: #333333;

        &.red {
            color: red;
        }
    }
}
.approval-list-container {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;

    .approval-list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .name-container {
            position: relative;
            display: flex;
            padding-bottom: 50px;
            flex-direction: row;

            &.active {
                &:before {
                    position: absolute;
                    background: #d3d4d6;
                    width: 1px;
                    top: 0px;
                    bottom: 0px;
                    left: 28px;
                    content: "";
                }
            }

            .info-container {
                flex-grow: 1;
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .info-top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .info-content {
                        font-size: 20px;
                        color: #333333;

                        i {
                            font-size: 14px;
                        }
                    }

                    .info-time {
                        font-size: 16px;
                        color: #999999;
                    }
                }

                .info-desc {
                    margin-top: 2px;
                    font-size: 16px;
                    color: #999999;
                }

                .copy-members {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-top: 20px;

                    .copy-members-name {
                        margin-right: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }

            span {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 56px;
                height: 56px;
                border-radius: 56px;
                border: 1px solid #3b77e7;
                text-align: center;
                /*line-height: 24px;*/
                font-size: 16px;
                color: #ffffff;
                background-color: #3b77e7;
                position: relative;

                i {
                    position: absolute;
                    bottom: -5px;
                    right: -5px;
                    font-size: 18px;
                    width: 18px;
                    height: 18px;
                    overflow: hidden;

                    &.el-icon-remove {
                        color: #ee9900;
                    }

                    &.el-icon-success {
                        color: #13ce66;
                    }

                    &.el-icon-error {
                        color: #ff0000;
                    }
                }
            }
        }
    }

    .approval-list-tip {
        font-size: 18px;
        color: #333333;
        flex-shrink: 1;
        margin-top: 12px;
    }
}
</style>